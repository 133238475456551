<template>
  <header>
    <ul>
      <li>
        <h1>werk.statt</h1>
      </li>
      <li>
        <a href="mailto:werk@statt.de">Kontakt</a>
      </li>
    </ul>
  </header>
</template>

<script>
export default {
  name: 'Navigation'
}
</script>

<style scoped>
header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 3rem;
  background: rgb(255,255,255);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
  line-height: 1; 
  padding: 1rem;
}

ul {
  list-style: none;
}

li {
  display: inline-block;
  margin-right: 1rem;
}
</style>
