<template>
  <div>
    <Navigation></Navigation>
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import Navigation from './components/Navigation'

export default {
  name: 'App',
  components: {
    Navigation
  }
}
</script>

<style>
/* font imports */

@font-face {
  font-family: 'IBM Plex Mono';
  src: url('./assets/IBMPlexMono-Light.woff2');
  font-weight: lighter;
  font-style: normal
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: url('./assets/IBMPlexMono-LightItalic.woff2');
  font-weight: lighter;
  font-style: italic
}

/* variables */

:root {
  --serif: 'IBM Plex Mono', monospace;
  --fg: #000;
  --bg: #fff;
  --accent: #ffa3d7;
}

/* resets */

html {
  box-sizing: border-box;
}

* {
  font-size: 16px;
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
address {
  margin: 0;
  padding: 0;
}

/* basics */

body {
  font-family: var(--serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  font-weight: lighter;
  color: var(--fg);
  background: var(--bg);
}

h1,
h2,
h3 {
  font-size: 1rem;
  font-weight: inherit;
  margin-bottom: 1rem;
}

main {
  max-width: 38rem;
  padding: 1rem;
  padding-top: 3rem;
}

a {
  color: inherit;
}

p {
  margin-bottom: 1rem;
}

hr {
  width: 4rem;
  border-top: none;
  border-bottom: 1px solid var(--fg);
  margin: 0;
  margin-bottom: 1rem;
}
</style>
