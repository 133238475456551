<template>
  <div>
    <div class="notice">
      Die Website befindet sich derzeit in Arbeit.
    </div>
    <p>
      Der werk.statt e.V. ist ein Verein zur Förderung der Medienkultur in Osnabrück. Der Verein betreibt unter dem Namen "werk.statt" ein Medienlabor, das kulturellen Medienprojekten professionelle Produktionsmittel zur Verfügung stellt. Freie Mitarbeiter und Ehrenamtliche führen dort Projekte durch und unterstützen Projekte von Einzelpersonen, Gruppen und Institutionen.
    </p>
    <p>
      Der werk.statt e.V. beschäftigt sich mit technisch-medialen Kommunikationsformen und medienpädagigischer Arbeit. Ein wichtiger Teil der Vereinstätigkeit ist die Untersuchung und Erprobung neuer medialer Kommunikationsformen im Hinblick auf ihren Nutzen für die regionale Kulturarbeit
    </p>
    <p>
      Der Verein fördert den Einsatz von Medien in der Kultur und versteht sich als Kompetenzzentrum. Sein Anliegen ist die Vermittlung von fachlicher und künstlerischer (Medien-)Kompetenz unabhängig von wirtschaftlichen Verwertungsmodellen.
    </p>
    <hr>
    <p>
      Der werk.statt e.V. wird gefördert durch die Stadt Osnabrück.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>
.notice {
  text-align: center;
  background: var(--accent);
  padding: 1rem;
  margin-bottom: 1rem;
}
</style>
